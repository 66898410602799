import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router';

const routes = [
    // {
    //     path: "/",
    //     name: "index",
    //     meta: {title: "首页"},
    //     component: () => import("@/views/index.vue")
    // },
    {
        path: "/",
        children: [
            {
                path: "/",
                name: "index",
                component: () => import("@/views/index.vue"),
                meta: {title: "首页"}
            },
            {
                path: "/about",
                name: "about",
                meta: {title: "关于我们"},
                component: () => import("@/views/about/index.vue")
            },
            {
                path: "/contact",
                name: "contact",
                meta: {title: "联系我们"},
                component: () => import("@/views/contact/index.vue")
            },
            {
                path: "/tihuo",
                name: "tihuo",
                meta: {title: "提货系统"},
                component: () => import("@/views/tihuo/index.vue")
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.path) {
        if (window._hmt) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
        }
    }
    next();
});
export default router;
